import { Form } from "@remix-run/react";
import { SectionHeader, SecondaryBodyDiv, SecondaryBody } from "~/components/typography";
import { Button } from "~/components/button";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useEffectOnce, useUpdateEffect } from "react-use";
import "./application_form.scss";
import type { FieldValue } from "./field";
import { Field } from "./field";
import type CsrfToken from "~/types/csrf_token";
import { DemographicSection } from "./demographic_section";
import { EeocForm } from "~/components/job_posts/eeoc/form";
import UploadManager from "~/utils/uploads/upload_manager";
import { useTranslation } from "react-i18next";
import {
  buildApplication,
  type DemographicAnswer,
  type EmploymentAnswers,
} from "~/utils/build_application";
import {
  type JobPost,
  type DemographicSection as DemographicSectionType,
  type Question,
  type ComplianceAnswers,
  type Field as FieldType,
} from "~/types/jben/job_post";
import {
  validateApplication,
  type EducationErrors,
  type ErrorWithLink,
} from "~/utils/validate_application";
import { type QuickApplyProfile } from "~/quick_apply/types";
import { isDemographicDataConsentRequired } from "~/utils/demographic_data_consent";
import { submitApplication } from "~/utils/submit_application";
import { ComplianceSection } from "./compliance_section";
import { scrollToElement } from "~/utils/scroll_to_element";
import { useBoardConfiguration } from "~/hooks/use_board_configuration";
import { EmploymentSection } from "./employment_section";
import { employmentRequired, showEmployment } from "~/utils/job_helpers";
import { type EducationAnswer, EducationSection } from "./education_section";
import EmailField from "~/components/job_posts/email_field";
import { ErrorFlash, LoadingFlash } from "~/components/flash";
import { MapboxResult, PeliasResult } from "@grnhse/location-control";
import useRecaptcha from "~/hooks/use_recaptcha";
import { Resizer } from "~/utils/resizer";
import EmailVerification from "./email_verification";
import { useTrackingParams } from "~/hooks/use_tracking_params";
import { useQuickApply } from "~/quick_apply/hooks/use_quick_apply";
import { UnauthorizedError } from "~/quick_apply/client";
import { type AutofillField, shouldTriggerMagicAutofill } from "~/quick_apply/util";
import { useAutofillerRef } from "~/quick_apply/hooks/use_autofiller_ref";
import { QuickApplyFlash } from "../../quick_apply/quick_apply_flash";
import { toSnakeCase } from "~/utils/string_helpers";
import rollbar from "~/utils/rollbar.client";

interface Props {
  csrfToken?: CsrfToken | null;
  jobPost: JobPost;
  demographicSection: DemographicSectionType | null | undefined;
  questions: Question[];
  submitPath: string;
  confirmationPath: string;
  internal?: boolean;
  embedded?: boolean;
  formRef?: React.RefObject<HTMLFormElement>;
  initialValues?: Record<string, FieldValue>;
}

export type FormField = FieldType & {
  label: string;
  description?: string | null;
  required: boolean;
  multiSelectStyle?: string;
  allowManual: boolean;
  error?: string;
  allowedFiletypes?: string[];
  custom: boolean;
};

const SECURITY_CODE_ERRORS = {
  invalid: "invalid-security-code",
  expired: "expired-security-code",
  exceeded: "security-code-error",
};

function displayEducation(jobPost: JobPost) {
  if (!jobPost || !jobPost.education_config) {
    return false;
  }

  for (const value of Object.values(jobPost.education_config)) {
    if (value === "optional" || value === "required") {
      return true;
    }
  }

  return false;
}

export const ApplicationForm = ({
  csrfToken,
  jobPost,
  questions,
  submitPath,
  confirmationPath,
  internal,
  embedded,
  formRef,
  initialValues,
}: Props) => {
  const { t } = useTranslation("job_post");
  const [quickApplyLoading, setQuickApplyLoading] = useState(false);
  const [autofillStatus, setAutofillStatus] = useState<"success" | "error" | null>(null);
  const { quickApplyActive, quickApplyClient, quickApplyMagicActive } = useQuickApply();
  const [jobSeekerProfile, setJobSeekerProfile] = useState<QuickApplyProfile | null>(null);

  const [answers, setAnswers] = useState<Record<string, FieldValue>>(initialValues || {});
  const [demographicAnswers, setDemographicAnswers] = useState<Record<string, DemographicAnswer[]>>(
    {}
  );
  const [complianceAnswers, setComplianceAnswers] = useState<ComplianceAnswers>({});
  const [employmentAnswers, setEmploymentAnswers] = useState<EmploymentAnswers>([{ key: 0 }]);
  const [educationAnswers, setEducationAnswers] = useState<EducationAnswer[]>([{ key: 0 }]);
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [demographicErrors, setDemographicErrors] = useState<Record<string, string>>({});
  const [complianceErrors, setComplianceErrors] = useState<Record<string, string | ErrorWithLink>>(
    {}
  );
  const [employmentErrors, setEmploymentErrors] = useState<Record<string, string>[]>([]);
  const [educationErrors, setEducationErrors] = useState<Record<number, EducationErrors>>({});
  const [eeocErrors, setEeocErrors] = useState<Record<string, string>>({});
  const [submitErrorMessage, setSubmitErrorMessage] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [formDisabled, setFormDisabled] = useState(false);
  const [showSecurityCodeForm, setShowSecurityCodeForm] = useState(false);
  const [securityCode, setSecurityCode] = useState("");
  const [securityCodeRecipient, setSecurityCodeRecipient] = useState("");
  const [securityCodeError, setSecurityCodeError] = useState("");
  const [autofillAttempted, setAutofillAttempted] = useState(false);
  const [captchaFailed, setCaptchaFailed] = useState(false);
  const [locationServiceUnavailable, setLocationServiceUnavailable] = useState(false);

  const { button_shape, disable_captcha, disable_eeoc_quick_apply, preview_mode } =
    useBoardConfiguration();
  const recaptchaClient = useRecaptcha(
    Boolean(internal),
    Boolean(embedded),
    !!(disable_captcha || preview_mode)
  );

  const s3Manager = useRef(new UploadManager());

  const displayEducationForm = useMemo(() => displayEducation(jobPost), [jobPost]);

  const { trackingParams } = useTrackingParams();

  const hasEeocSection = Boolean(jobPost.eeoc_sections);

  // Use a ref for autofiller because answers can get out of sync in async functions.
  // This forces the autofiller to always use the latest answers because up to date
  // ref values can be retrieved outside of the render cycle
  const autofillerRef = useAutofillerRef(
    answers,
    educationAnswers,
    employmentAnswers,
    jobPost,
    questions,
    hasEeocSection,
    disable_eeoc_quick_apply,
    setAnswers,
    setEducationAnswers,
    setEmploymentAnswers
  );

  const demographicDataConsentRequired = useMemo(() => {
    return isDemographicDataConsentRequired(jobPost, Object.values(demographicAnswers));
  }, [demographicAnswers, jobPost]);

  useEffectOnce(() => {
    !preview_mode &&
      quickApplyClient.fetchProfile().then((profile) => {
        if (profile) {
          setJobSeekerProfile(profile);
        }
      });
  });

  useEffect(() => {
    const inputFiles = s3Manager.current.inputFilesFrom(questions);
    s3Manager.current.fetchFields(inputFiles);
  }, [questions, s3Manager]);

  useEffect(() => {
    const { data_compliance, demographic_questions } = jobPost;

    if (!data_compliance) return;

    let complianceAnswers: ComplianceAnswers = {};

    if (data_compliance.single_purpose_consent) {
      if (data_compliance.requires_processing_consent) {
        complianceAnswers["gdpr_processing_consent_given"] = false;
      }
      if (data_compliance.requires_retention_consent) {
        complianceAnswers["gdpr_retention_consent_given"] = false;
      }
    } else if (data_compliance.retention_period) {
      // if the job post has a retention period and the data_compliance object is included,
      // then the candidate must give consent to processing
      complianceAnswers["gdpr_consent_given"] = false;
    }

    if (
      data_compliance.single_purpose_consent &&
      data_compliance.demographic_data_consent &&
      data_compliance.demographic_data_consent_applies &&
      demographic_questions
    ) {
      complianceAnswers["gdpr_demographic_data_consent_given"] = false;
    }

    setComplianceAnswers(complianceAnswers);
  }, [jobPost]);

  useEffect(() => {
    // If the file upload field has an error, the attach button will be annotated with data-error="true"
    // since aria-invalid isn't supported on buttons
    const elementWithError = document.querySelector<HTMLElement>(
      '[aria-invalid="true"], [data-error="true"], .helper-text--error'
    );

    if (!elementWithError) {
      return;
    }

    scrollToElement(elementWithError);
    elementWithError.focus();
  }, [errors, demographicErrors, complianceErrors, employmentErrors, educationErrors, eeocErrors]);

  useUpdateEffect(() => {
    Resizer.getInstance().handleResize();
  }, [
    answers,
    errors,
    demographicErrors,
    complianceErrors,
    employmentErrors,
    educationErrors,
    eeocErrors,
    complianceAnswers,
    showSecurityCodeForm,
  ]);

  const handleSubmitErrorMessage = useCallback((message: string, error?: Error) => {
    if (error) {
      console.error(error);
    }

    setSubmitErrorMessage(message);
    setSubmitting(false);
  }, []);

  const fields = useMemo(
    () =>
      questions.map((question): FormField => {
        const field = question.fields[0];

        const isFile = field.type === "input_file";

        const allowManual = isFile && question.fields.length > 1;

        return {
          ...field,
          label: question.label,
          description: question.description,
          required: question.required,
          multiSelectStyle: question.multi_select_style,
          allowManual,
          error: errors[field.name],
          allowedFiletypes: field.allowed_filetypes,
          custom: field.name.startsWith("question_"),
        };
      }),
    [questions, errors]
  );

  const prepareRequest = (event: React.SyntheticEvent) => {
    event.preventDefault();

    if (submitting || formDisabled) {
      return;
    }

    setSubmitting(true);
    setSecurityCodeError("");

    const application = buildApplication(
      jobPost,
      answers,
      demographicAnswers,
      complianceAnswers,
      employmentAnswers,
      educationAnswers,
      trackingParams
    );

    const validationResult = validateApplication(jobPost, application, locationServiceUnavailable);

    // want to keep this error message, but not have it block application validity
    if (locationServiceUnavailable) {
      validationResult.basicErrors["candidate_location"] = t(
        "application.errors.location_service_unavailable"
      );
    }

    if (!validationResult.isValid) {
      setSubmitting(false);
      setErrors(validationResult.basicErrors);
      setDemographicErrors(validationResult.demographicErrors);
      setComplianceErrors(validationResult.complianceErrors);
      setEmploymentErrors(validationResult.employmentErrors);
      setEducationErrors(validationResult.educationErrors);
      setEeocErrors(validationResult.eeocErrors);

      return;
    }

    return application;
  };

  const handleErrors = async (response: Response) => {
    const body = await response.json();

    if (body.code === "captcha-failed") {
      // Failed invisible reCAPTCHA, render email verification fallback
      if (body.security_code_recipient) {
        setFormDisabled(true);
        setSecurityCodeRecipient(body.security_code_recipient);
        setShowSecurityCodeForm(true);
      } else {
        // reCAPTCHA failed but not a candidate for retry or email verification
        handleSubmitErrorMessage(t("application.errors.captcha_message"));
      }
    }

    if (body.code === "captcha-retry") {
      // reCAPTCHA threw a retryable error, prompt to resubmit without amendment
      setFormDisabled(false);
      setCaptchaFailed(true);
      handleSubmitErrorMessage(t("application.errors.generic_failure"));
    }

    if (Object.values(SECURITY_CODE_ERRORS).includes(body.code)) {
      const { expired, exceeded } = SECURITY_CODE_ERRORS;

      if (body.code === expired || body.code === exceeded) {
        setFormDisabled(true);
      }
      setSecurityCodeError(body.message);
    }

    if (body.code === "invalid-attributes") {
      const attributes = body.message.split(",");
      const eeocFields =
        jobPost.eeoc_sections?.flatMap(
          (section) =>
            section?.questions?.map((question) => ({
              name: question.fields[0].name,
              label: question.label,
            })) || []
        ) ?? [];
      const complianceFields = [
        {
          name: jobPost.data_compliance?.single_purpose_consent
            ? "gdpr_processing_consent_given"
            : "gdpr_consent_given",
          label: "",
        },
      ];
      const allFields = [...fields, ...eeocFields, ...complianceFields];
      let invalidErrors: { [key: string]: string } = {};

      for (const attribute of attributes) {
        const field = allFields.find((field) => field.name === attribute);

        if (!field) {
          continue;
        }

        if (attribute.includes("gdpr")) {
          invalidErrors[attribute] = t("application.accept_terms");
          continue;
        }

        invalidErrors[attribute] = t("application.errors.invalid_attribute", {
          attribute: field.label.toLowerCase(),
        });
      }

      setErrors(invalidErrors);
      setEeocErrors(invalidErrors);
      setComplianceErrors(invalidErrors);

      if (Object.keys(invalidErrors).length === 0) {
        handleSubmitErrorMessage(t("application.errors.generic_failure"));
      }
    }

    if (body.code === "invalid-attachment") {
      let invalidErrors: { [key: string]: string } = {};
      const fieldNames = fields.map((field) => field.name);
      const errorMessages = body.message.split(".").filter((message: string) => !!message);

      errorMessages.forEach((message: string) => {
        const match = message.trim().match(/^Uploaded (.*?) has an unsupported file type$/);
        if (match && match[1]) {
          const erroredFieldName = toSnakeCase(match[1]);
          if (fieldNames.includes(erroredFieldName)) {
            invalidErrors[erroredFieldName] = t("application.errors.invalid_filetype");
          }
        }
      });
      setErrors(invalidErrors);

      const invalidErrorsLength = Object.keys(invalidErrors).length;
      if (invalidErrorsLength === 0 || invalidErrorsLength !== errorMessages.length) {
        handleSubmitErrorMessage(t("application.errors.generic_invalid_filetype"));
      }
    }

    setSubmitting(false);
  };

  const handleSubmit = async (event: React.SyntheticEvent) => {
    if (preview_mode) {
      return;
    }
    const application = prepareRequest(event);
    if (!application) {
      return;
    }

    let response = {} as Response;

    try {
      response = await submitApplication({
        application,
        submitPath,
        csrfToken: csrfToken || null,
        fingerprint: jobPost.fingerprint,
        recaptchaClient,
        securityCode,
        captchaFailed,
      });
    } catch (e) {
      const error = e as Error;
      handleSubmitErrorMessage(t("application.errors.generic_failure"), error);
    }

    if (response.ok) {
      window.location.assign(confirmationPath);
    } else {
      await handleErrors(response);
    }
  };

  const handleChange = (key: string, value: FieldValue) => {
    setAnswers({
      ...answers,
      [key]: value,
    });
  };

  const handleFileInputChange = (key: string, value: FieldValue) => {
    let updatedAnswers: { [key: string]: string | FieldValue | null };
    const textKey = `${key}_text`;

    if (typeof value === "string") {
      updatedAnswers = {
        [key]: null,
        [textKey]: value,
      };
    } else {
      updatedAnswers = {
        [key]: value,
        [textKey]: null,
      };
    }

    setAnswers((previousAnswers) => ({ ...previousAnswers, ...updatedAnswers }));
  };

  const handleCandidateLocationChange = (location: FieldValue) => {
    // @ts-ignore - Issue around <Field> onChange being overloaded
    if (location === "SERVICE UNAVAILABLE") {
      setErrors({
        ...errors,
        candidate_location: t("application.errors.location_service_unavailable"),
      });
      setLocationServiceUnavailable(true);
      return;
    }
    if (!(location instanceof MapboxResult || location instanceof PeliasResult)) {
      return setAnswers({
        ...answers,
        location: null,
        latitude: null,
        longitude: null,
      });
    }

    setAnswers({
      ...answers,
      location: location.displayName,
      latitude: location.latitude.toString(),
      longitude: location.longitude.toString(),
    });
  };

  const handleDemographicChange = (key: string, value: DemographicAnswer[]) => {
    setDemographicAnswers({
      ...demographicAnswers,
      [key]: value,
    });
  };

  const handleComplianceChange = (key: keyof ComplianceAnswers, value: boolean) => {
    setComplianceAnswers({
      ...complianceAnswers,
      [key]: value,
    });

    // For parity with JBEN, if the candidate reverses their processing consent decision,
    // the retention consent decision should be reset.
    if (
      "gdpr_retention_consent_given" in complianceAnswers &&
      key === "gdpr_processing_consent_given" &&
      !value
    ) {
      setComplianceAnswers((complianceAnswers) => ({
        ...complianceAnswers,
        gdpr_retention_consent_given: false,
      }));
    }
  };

  const attemptAutofillFromButton = async () => {
    let profile = jobSeekerProfile;

    setAutofillAttempted(true);

    if (!profile) {
      setQuickApplyLoading(true);
      setAutofillStatus(null);

      // This will open a new window to be used for login. This has to be done before calling
      // any async methods because popup blockers flag windows opened from async processes.
      // The window will be closed almost immediately if the user is already logged in.
      quickApplyClient.initiateLoginFlow();

      try {
        profile = await quickApplyClient.fetchProfile();

        setQuickApplyLoading(false);
      } catch (e) {
        setQuickApplyLoading(false);

        if (e instanceof UnauthorizedError) {
          profile = await quickApplyClient.fetchProfileAfterLogin();
        } else {
          setAutofillStatus("error");
        }
      } finally {
        quickApplyClient.endLoginFlow();
      }

      if (!profile) {
        setAutofillStatus("error");
        return;
      } else {
        setJobSeekerProfile(profile);
      }
    }

    autofillerRef.current.fill(profile, setAutofillStatus);
  };

  const handleQuickApplyClick = () => {
    if (preview_mode || !quickApplyActive || quickApplyLoading) {
      return;
    }

    // If not an embedded board, just attempt the autofill (it's hosted on our domain, so no need to worry about 3rd party cookies).
    // On an embedded board, we need to make sure we have access to 3rd party cookies before attempting to autofill. This either
    // comes back true if they're already enabled, or opens a popup to the user to request access.
    // If it returns false because there's no cookie present, we should go through the flow to login, which could result in an autofill,
    // or the user needing to click the button again to give 3rd party cookie access to autofill now that there's a cookie.
    // If the user simply declines access to 3rd party cookies, we should end the flow.
    if (!embedded) {
      attemptAutofillFromButton();
    } else {
      document.requestStorageAccess().then(attemptAutofillFromButton, () => {
        if (!document.cookie) {
          attemptAutofillFromButton();
        } else {
          rollbar.debug("User declined access to third-party cookies.");
        }
      });
    }
  };

  const handleMagicAutoFill = async (initiator: AutofillField) => {
    if (preview_mode || !quickApplyMagicActive || quickApplyLoading || autofillAttempted) {
      return;
    }

    if (!shouldTriggerMagicAutofill(initiator)) {
      return;
    }

    setAutofillAttempted(true);
    setQuickApplyLoading(true);

    try {
      const profile = jobSeekerProfile || (await quickApplyClient.fetchProfile());

      if (profile) {
        setJobSeekerProfile(profile);
      }

      autofillerRef.current.fill(profile, setAutofillStatus);
    } finally {
      setQuickApplyLoading(false);
    }
  };

  const renderStandardFields = () => renderFields(false);
  const renderCustomFields = () => renderFields(true);
  const renderFields = (renderCustom = false) => {
    return (
      <div className="application--questions">
        {fields
          .filter((f) => f.custom === renderCustom)
          .map((field) => {
            const FieldComponent = field.name === "email" ? EmailField : Field;

            return (
              <div key={field.name}>
                <FieldComponent
                  label={field.label}
                  name={field.name}
                  type={field.type}
                  options={field.values}
                  required={field.required}
                  multiSelectStyle={field.multiSelectStyle}
                  error={field.error}
                  internal={Boolean(internal)}
                  allowManual={field.allowManual}
                  onChange={(value) => {
                    let key = field.name;

                    if (field.type === "input_file") {
                      if (preview_mode) {
                        return;
                      }
                      handleFileInputChange(key, value);
                    } else if (field.name === "candidate_location") {
                      handleCandidateLocationChange(value);
                    } else {
                      handleChange(key, value);
                    }
                  }}
                  onFocus={() => handleMagicAutoFill(field)}
                  uploadManager={s3Manager.current}
                  value={answers[field.name]}
                  allowedFiletypes={field.allowedFiletypes}
                  location={answers.location as string}
                />

                {field.description && (
                  <SecondaryBodyDiv>
                    <div
                      id={`${field.name}-description`}
                      className="question-description"
                      dangerouslySetInnerHTML={{ __html: field.description }}
                    ></div>
                  </SecondaryBodyDiv>
                )}
              </div>
            );
          })}
      </div>
    );
  };

  return (
    <div className="application--container">
      <div className="application--header">
        <div className="application--header--text">
          <div className="application--header--title">
            <SectionHeader as="h2">{t("application.apply_to_job")}</SectionHeader>
          </div>
          <div className="application--header--required">
            <div className="application--header--required--asterisk">
              <SecondaryBody>{"*"}</SecondaryBody>
            </div>
            <SecondaryBody>{t("application.required")}</SecondaryBody>
          </div>
        </div>
        {quickApplyActive && (
          <div className="application--header--autofill-with-greenhouse">
            <Button
              onClick={handleQuickApplyClick}
              secondary
              disabled={submitting || formDisabled || quickApplyLoading}
              shape={button_shape || undefined}
            >
              {t("application.quick_apply.autofill")}
            </Button>
          </div>
        )}
      </div>
      <Form
        id={"application-form"}
        onSubmit={handleSubmit}
        className="application--form"
        ref={formRef}
      >
        {renderStandardFields()}

        {showEmployment(jobPost) && (
          <EmploymentSection
            employments={employmentAnswers}
            required={employmentRequired(jobPost)}
            internal={internal}
            errors={employmentErrors}
            onChange={setEmploymentAnswers}
            onFocus={() => handleMagicAutoFill({ name: "employment" })}
          />
        )}
        {displayEducationForm && (
          <EducationSection
            educationConfiguration={jobPost.education_config!}
            onChange={setEducationAnswers}
            answers={educationAnswers}
            errors={educationErrors}
            onFocus={() => handleMagicAutoFill({ name: "education" })}
          />
        )}

        <hr />

        {renderCustomFields()}

        {jobPost.demographic_questions && (
          <>
            <hr />
            <DemographicSection
              title={jobPost.demographic_questions.title}
              description={jobPost.demographic_questions.description}
              questions={jobPost.demographic_questions.questions}
              errors={demographicErrors}
              onChange={handleDemographicChange}
            />
          </>
        )}

        {jobPost.eeoc_sections && (
          <EeocForm
            sections={jobPost.eeoc_sections}
            errors={eeocErrors}
            onChange={handleChange}
            answers={answers}
          />
        )}

        {jobPost.data_compliance && (
          <>
            <hr />
            <ComplianceSection
              internal={internal}
              complianceAnswers={complianceAnswers}
              data={jobPost.data_compliance}
              errors={complianceErrors}
              companyName={jobPost.company_name}
              onChange={handleComplianceChange}
              demographicDataConsentRequired={demographicDataConsentRequired}
            />
          </>
        )}
        {showSecurityCodeForm && (
          <EmailVerification
            setFormDisabled={setFormDisabled}
            setSecurityCode={setSecurityCode}
            email={securityCodeRecipient}
            error={securityCodeError}
          />
        )}
        {submitErrorMessage && (
          <>
            <ErrorFlash message={submitErrorMessage} />
            <p id="submit-error" className="helper-text helper-text--error" aria-live="polite">
              {submitErrorMessage}
            </p>
          </>
        )}
        {quickApplyLoading && <LoadingFlash message={t("application.quick_apply.loading")} />}
        {autofillStatus && (
          <QuickApplyFlash
            type={autofillStatus}
            closeFlash={() => setAutofillStatus(null)}
            embedded={embedded}
          />
        )}
        <div className="application--submit">
          <Button
            type="submit"
            onClick={handleSubmit}
            loading={submitting}
            disabled={submitting || formDisabled}
            shape={button_shape || undefined}
          >
            {t("application.submit")}
          </Button>
        </div>
      </Form>
    </div>
  );
};
