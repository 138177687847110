import { type EducationAnswer as FormEducationAnswer } from "~/components/job_posts/education_section";
import type {
  SelectedOption,
  Answer,
  Application,
  DemographicQuestionAnswer,
  ApplicationEmploymentAnswer,
  EducationAnswer as ApplicationEducationAnswer,
} from "~/types/jben/application";
import type { ComplianceAnswers, JobPost } from "~/types/jben/job_post";
import { type UploadedFile, isUploadedFile } from "~/utils/uploads/uploaded_file";

type BasicAnswers = Record<string, string | string[] | UploadedFile | number | null>;

export interface DemographicAnswer {
  id: string;
  freeform?: string;
}

export interface EmploymentAnswer {
  key?: number;
  companyName?: string;
  title?: string;
  startDateMonth?: number;
  startDateYear?: number;
  endDateMonth?: number;
  endDateYear?: number;
  currentRole?: boolean;
}

type DemographicAnswers = Record<string, DemographicAnswer[]>;
type AnswersAttributes = Record<string, Answer>;
export type EmploymentAnswers = EmploymentAnswer[];

type StandardKey =
  | "first_name"
  | "last_name"
  | "preferred_name"
  | "email"
  | "phone"
  | "resume_text"
  | "cover_letter_text"
  | "location"
  | "latitude"
  | "longitude";

const STANDARD_KEYS: Readonly<Set<StandardKey>> = new Set([
  "first_name",
  "last_name",
  "preferred_name",
  "email",
  "phone",
  "resume_text",
  "cover_letter_text",
  "location",
  "latitude",
  "longitude",
]);

export function isStandardKey(key: string): key is StandardKey {
  return STANDARD_KEYS.has(key as StandardKey);
}

function buildInitialApplication(answers: BasicAnswers): Application {
  const initialApplication: Application = {
    first_name: "",
    last_name: "",
    email: "",
    answers_attributes: {} as Record<number, Answer>,
    demographic_answers: [] as DemographicQuestionAnswer[],
    data_compliance: {},
    attachments: {} as Record<string, string>,
    from_job_board_renderer: true,
    employments: [] as ApplicationEmploymentAnswer[],
  };

  for (const key of STANDARD_KEYS) {
    const value = answers[key];

    if (value && typeof value === "string") {
      initialApplication[key] = value;
    }
  }

  const resume = answers["resume"];
  const coverLetter = answers["cover_letter"];

  if (resume && isUploadedFile(resume)) {
    initialApplication.resume_url = resume.url;
    initialApplication.resume_url_filename = resume.name;
  }

  if (coverLetter && isUploadedFile(coverLetter)) {
    initialApplication.cover_letter_url = coverLetter.url;
    initialApplication.cover_letter_url_filename = coverLetter.name;
  }

  return initialApplication;
}

function buildDemographicAnswers(
  jobPost: JobPost,
  answers: DemographicAnswers
): DemographicQuestionAnswer[] {
  const result: DemographicQuestionAnswer[] = [];

  for (const question of jobPost?.demographic_questions?.questions || []) {
    const key = question.id.toString();

    const freeformOptionId = question.answer_options
      .find((option) => option.free_form)
      ?.id.toString();

    const questionAnswers = answers[key]?.filter(
      (answer) => answer.id !== freeformOptionId || answer.freeform !== ""
    );

    if (!questionAnswers || questionAnswers.length === 0) {
      continue;
    }

    result.push({
      question_id: question.id,
      answer_options: questionAnswers.map((answer) => {
        if (answer.freeform) {
          return {
            answer_option_id: answer.id,
            text: answer.freeform,
          };
        }
        return { answer_option_id: answer.id };
      }),
    });
  }

  return result;
}

export function keyToQuestionId(key: string): string | null {
  if (!key.startsWith("question_")) {
    return null;
  }

  let questionId = key.split("_")[1];

  if (questionId.endsWith("[]")) {
    questionId = questionId.substring(0, questionId.length - 2);
  }

  return questionId;
}

function fieldsForJobPost(jobPost: JobPost) {
  return jobPost.questions.map((question) => {
    const field = question.fields[0];

    return {
      name: field.name,
      type: field.type,
    };
  });
}

function buildAnswersAttributes(jobPost: JobPost, answers: BasicAnswers): AnswersAttributes {
  const result = {} as AnswersAttributes;

  let priority = 0;

  const fields = fieldsForJobPost(jobPost);

  for (const field of fields) {
    const key = field.name;
    const value = answers[key];

    if (isStandardKey(key)) {
      continue;
    }

    if (key === "resume" || key === "cover_letter") {
      continue;
    }

    const questionId = keyToQuestionId(key);

    if (!questionId) {
      continue;
    }

    switch (field.type) {
      case "input_text":
      case "textarea":
        result[questionId] = {
          question_id: questionId,
          priority: priority++,
          text_value: answers[key] as string,
        };
        continue;
      case "multi_value_single_select":
      case "multi_value_multi_select":
        let selectedOptionIds: string[] | number[] | null;
        let yesNoQuestion = false;

        if (!Array.isArray(value)) {
          if (!value && value !== 0) {
            selectedOptionIds = null;
          } else if (+value === 1 || +value === 0) {
            yesNoQuestion = true;
            selectedOptionIds = null;
          } else {
            selectedOptionIds = [value as string];
          }
        } else {
          selectedOptionIds = value as string[];
        }

        let optionAttributes = {} as Record<string, SelectedOption>;

        result[questionId] = {
          question_id: questionId,
          priority: priority++,
        };

        if (yesNoQuestion) {
          result[questionId]["boolean_value"] = +value!;
        } else {
          selectedOptionIds?.forEach((id, index) => {
            optionAttributes[index] = {
              question_option_id: id,
            };
          });
          result[questionId]["answer_selected_options_attributes"] = optionAttributes;
        }

        continue;
      case "input_file":
        // TODO: Handle custom attachments
        continue;
    }
  }

  return result;
}

function buildEducationAnswers(
  educationAnswers: FormEducationAnswer[]
): ApplicationEducationAnswer[] {
  const result: ApplicationEducationAnswer[] = [];

  for (const educationAnswer of educationAnswers) {
    result.push({
      school_name_id: educationAnswer.school?.value.toString() || null,
      degree_id: educationAnswer.degree?.value.toString() || null,
      discipline_id: educationAnswer.discipline?.value.toString() || null,
      start_date: {
        month: (educationAnswer.start_month?.value as number) || null,
        year: educationAnswer.start_year || null,
      },
      end_date: {
        month: (educationAnswer.end_month?.value as number) || null,
        year: educationAnswer.end_year || null,
      },
    });
  }

  return result;
}

function buildAttachments(jobPost: JobPost, answers: BasicAnswers): Record<string, string> {
  const result: Record<string, string> = {};

  const fields = fieldsForJobPost(jobPost);

  for (const field of fields) {
    const key = field.name;
    const value = answers[key];

    if (field.type !== "input_file") {
      continue;
    }

    if (isStandardKey(key) || !value || !isUploadedFile(value)) {
      continue;
    }

    if (key === "resume" || key === "cover_letter") {
      continue;
    }

    const questionId = keyToQuestionId(key);

    if (!questionId) {
      continue;
    }

    result[`${questionId}_url`] = value.url;
    result[`${questionId}_url_filename`] = value.name;
  }

  return result;
}

function setEeocAnswers(jobPost: JobPost, answers: BasicAnswers, application: Application) {
  if (!jobPost.eeoc_sections) {
    return;
  }

  jobPost.eeoc_sections.forEach((eeocSection) => {
    eeocSection.questions.forEach((question) => {
      const name = question.fields[0].name;
      const value = answers[name];

      if (typeof value === "string" && value.length > 0) {
        application[name] = value;
      }
    });
  });
}

function buildEmploymentAnswers(answers: EmploymentAnswers): ApplicationEmploymentAnswer[] {
  return answers.map((employment) => {
    return {
      company_name: employment.companyName,
      title: employment.title,
      start_date: {
        month: employment.startDateMonth || null,
        year: employment.startDateYear || null,
      },
      end_date: employment.currentRole
        ? undefined
        : {
            month: employment.endDateMonth || null,
            year: employment.endDateYear || null,
          },
      current: !!employment.currentRole,
    };
  });
}

export function buildApplication(
  jobPost: JobPost,
  basicAnswers: BasicAnswers,
  demographicAnswers: DemographicAnswers,
  complianceAnswers: ComplianceAnswers,
  employmentAnswers: EmploymentAnswers,
  educationAnswers: FormEducationAnswer[],
  trackingParams: URLSearchParams
): Application {
  const application = buildInitialApplication(basicAnswers);

  application.answers_attributes = buildAnswersAttributes(jobPost, basicAnswers);

  setEeocAnswers(jobPost, basicAnswers, application);

  if (jobPost.demographic_questions) {
    application.demographic_answers = buildDemographicAnswers(jobPost, demographicAnswers);
  }

  if (educationAnswers.length > 0) {
    application.educations = buildEducationAnswers(educationAnswers);
  }

  application.data_compliance = complianceAnswers;

  application.attachments = buildAttachments(jobPost, basicAnswers);

  application.employments = buildEmploymentAnswers(employmentAnswers);

  application.mapped_url_token = trackingParams.get("t") || trackingParams.get("gh_src");

  application.appcast_click_id = trackingParams.get("ccuid");

  return application;
}
